<template>
  <div class="background">
    <div class="row mb-5 mt-5 align-items-center justify-content-center">
      <div class="col-auto">
        <h1 class="tracking-heading">Tracking Events</h1>
      </div>
    </div>

    <div class="row justify-content-center">
      <events-feed :sticky-id="$route.params.id" v-if="$route.params.id" />
    </div>
  </div>
</template>

<script>
import EventsFeed from "./StickyEvents/Feed";

export default {
  components: {
    EventsFeed,
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: white;
  padding: 10px;
}
.tracking-heading {
  font-size: 2em;
}
</style>